
import '@stripe/stripe-js'

export default function donateModal (el, props) {
  let $el = $(el)

  const stripeKey = el.getAttribute("data-stripe-key")

  const continueButton = el.querySelector('.custom_amount_submit')
  const amountCentsInput = el.querySelector('.amount_cents')
  const amountDollarsInput = el.querySelector('.user_amount_dollars')
  const form = el.querySelector('.donation_form')
  const amountValidationMessage = el.querySelector('.amount-validation-message')

  const ten = el.querySelector('.ten')
  const twenty = el.querySelector('.twenty')
  const thirty = el.querySelector('.thirty')
  const fifty = el.querySelector('.fifty')
  const oneHundred = el.querySelector('.one-hundred')
  const twoHundred = el.querySelector('.two-hundred')

  function onAmountClick(e) {
    e.preventDefault()
    amountCentsInput.value = e.currentTarget.getAttribute('data-amount')
    form.submit()
  }

  ten.addEventListener("click", onAmountClick)
  twenty.addEventListener("click", onAmountClick)
  thirty.addEventListener("click", onAmountClick)
  fifty.addEventListener("click", onAmountClick)
  oneHundred.addEventListener("click", onAmountClick)
  twoHundred.addEventListener("click", onAmountClick)

  function onSubmit(e) {
    e.preventDefault()

    if (amountDollarsInput.value && amountDollarsInput.value >= 1) {
      amountCentsInput.value = amountDollarsInput.value * 100
      form.submit()
    } else {
      amountValidationMessage.style.display = 'block'
    }
  }

  continueButton.addEventListener("click", onSubmit)

  function destroy() {
    continueButton.removeEventListener("click", onSubmit)
    ten.removeEventListener("click", onAmountClick)
    twenty.removeEventListener("click", onAmountClick)
    thirty.removeEventListener("click", onAmountClick)
    fifty.removeEventListener("click", onAmountClick)
    oneHundred.removeEventListener("click", onAmountClick)
    twoHundred.removeEventListener("click", onAmountClick)
  }

  return {
    destroy: destroy
  };
}
