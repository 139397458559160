
// import '@stripe/stripe-js'
import {loadStripe} from '@stripe/stripe-js';


export default function subscriptionForm (el, props) {
  let $el = $(el)
  let errorMessageContainer = document.getElementById("card-errors")
  let subscribeButton = document.getElementById("subscribe-button")

  loadStripe(props.stripe_key).then((stripe) => {
    let priceId = props.price_id
    let customerName = props.customer_name

    let elements = stripe.elements()

    let style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "22px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }

    let cardElement = elements.create("card", {hidePostalCode: true, style: style })
    cardElement.mount("#card-element")

    cardElement.on('change', function(event) {
      var displayError = document.getElementById('card-errors')

      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    })

    let form = el

    function onFormSubmit(event) {
      event.preventDefault()
      subscribeButton.disabled = true

      const latestInvoicePaymentIntentStatus = localStorage.getItem(
        'latestInvoicePaymentIntentStatus'
      )

      if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        const invoiceId = localStorage.getItem('latestInvoiceId')
        const isPaymentRetry = true
        // create new payment method & retry payment on invoice with new payment method
        createPaymentMethod({
          cardElement,
          isPaymentRetry,
          invoiceId,
        })
      } else {
        // create new payment method & create subscription
        createPaymentMethod({ cardElement })
      }
    }

    form.addEventListener('submit', onFormSubmit)

    function createPaymentMethod({ cardElement, isPaymentRetry, invoiceId }) {
      stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: customerName,
        },
      })
      .then((result) => {
        if (result.error) {
          console.log(result)
          subscribeButton.disabled = false
        } else {
          if (isPaymentRetry) {
            // Update the payment method and retry invoice payment
            retryInvoiceWithNewPaymentMethod({
              // customerId: customerId,
              paymentMethodId: result.paymentMethod.id,
              invoiceId: invoiceId,
              priceId: priceId,
            });
          } else {
            // Create the subscription
            createSubscription({
              // customerId: customerId,
              paymentMethodId: result.paymentMethod.id,
              priceId: priceId,
            })
          }
        }
      })
    }
  })

  function createSubscription({ paymentMethodId, priceId }) {
    return (
      fetch('/subscriptions/create.json', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          // customer_id: customerId,
          payment_method_id: paymentMethodId,
          price_id: priceId,
        }),
      })
        .then((response) => {
          subscribeButton.disabled = false
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          showCardError(error)
        })
    );
  }

  function showCardError(error) {
    errorMessageContainer.textContent = error.error.message
  }

  function onSubscriptionComplete(result) {
    // Payment was successful.
    if (result.subscription.status === 'active') {
      window.location = "/subscriptions/success"
    }
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          subscribeButton.disabled = false

          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch((error) => {
          displayError(error);
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );
      throw { error: { message: 'Your card was declined.' } };
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function retryInvoiceWithNewPaymentMethod({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId
  }) {
    return (
      fetch('/subscriptions/retry.json', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          // customer_id: customerId,
          payment_method_id: paymentMethodId,
          invoice_id: invoiceId,
        }),
      })
        .then((response) => {
          subscribeButton.disabled = false
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          displayError(error);
        })
    );
  }


  // let stripe = Stripe(props.stripe_key)

}
