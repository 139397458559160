/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Polyfills
import 'es6-promise/auto'
import 'whatwg-fetch'

import Rails from "rails-ujs";
Rails.start();

import Turbolinks from "turbolinks";
Turbolinks.start();

import domready from 'domready'
import viewloader from 'viewloader'

import {default as views} from '../views'

import '../vendor/timeago'

let viewloaderManager = viewloader(views)

domready(function onDomready () {
  console.log('domready')
})

document.addEventListener('turbolinks:load', () => {
  console.log('turbolinks:load - viewloaderManager.callViews()')
  viewloaderManager.callViews()

  // Timeago - elements auto destroyed when not visible anymore
  $('time[data-time-ago]').timeago()
})

// DOMContentLoaded equivalent with Turbolinks
// document.addEventListener('turbolinks:render', function onTurboLinksLoad () {
  // console.log('turbolinks:render')
  // viewloaderManager.callViews()
// })

// Destroy views when navigating
document.addEventListener('turbolinks:before-render', () => {
  console.log('turbolinks:before-render - viewloaderManager.destroyViews()')
  viewloaderManager.destroyViews()
})

// forcefully close modals.
// See https://www.honeybadger.io/blog/turbolinks/
document.addEventListener('turbolinks:before-cache', () => {
  if (document.body.classList.contains('modal-open')) {
    $('.modal')
      .hide()
      .removeAttr('aria-modal')
      .attr('aria-hidden', 'true');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }
})

import 'bootstrap';
import '../fonts/feather/feather.css';
import '../styles/theme.scss';
import '../img/odonata-logo.png';
import '../img/odonata-logo-light.png';
import '../img/manuel-meurisse-unsplash.jpg';
import '../img/hero-image-02.jpg';
import '../img/mt-rothwell.png';
import '../img/mobile-wildlife.jpg';
import '../img/avatars/profiles/avatar-1.jpg';
import '../img/feather-sprite.svg';
import '../img/smoky-mouse-stuart-cohen.jpg';
import '../img/smoky-mouse-linda-broome-1.png';
import '../img/enviro-dna-logo.png';
import '../img/david-clode-platypus.jpg';
import '../img/byron-bay-wildlife-sanctuary.png';
import '../img/wra.png';
import '../img/possum.png';
import '../img/sos-logo-1.jpg';
import '../img/odonata-line.png';

require("trix")
require("@rails/actiontext")
